import { theme } from '@fountain/fountain-ui-components';

import { TableColumnWidthsKey } from './types';

export const ADD_NEW_MESSAGE = 'app/ApplicantTableV2/ADD_NEW_MESSAGE';
export const ADD_NOTE = 'app/ApplicantTableV2/ADD_NOTE';
export const BULK_ACTION_INIT = 'app/ApplicantTableV2/BULK_ACTION_INIT';
export const BULK_ACTION_SUBMITTED =
  'app/ApplicantTableV2/BULK_ACTION_SUBMITTED';
export const DELETE_APPLICANT_START =
  'app/ApplicantTableV2/DELETE_APPLICANT_START';
export const DELETE_APPLICANT_SUCCESS =
  'app/ApplicantTableV2/DELETE_APPLICANT_SUCCESS';
export const DELETE_APPLICANT_ERROR =
  'app/ApplicantTableV2/DELETE_APPLICANT_ERROR';
export const FETCH_APPLICANT_DETAILS =
  'app/ApplicantTableV2/FETCH_APPLICANT_DETAILS';
export const FETCH_APPLICANT_DETAILS_DONE =
  'app/ApplicantTableV2/FETCH_APPLICANT_DETAILS_DONE';
export const SET_APPLICANT_BLOB = 'app/ApplicantTableV2/SET_APPLICANT_BLOB';
export const SET_APPLICANT_BLOBS = 'app/ApplicantTableV2/SET_APPLICANT_BLOBS';
export const SET_APPLICANTS = 'app/ApplicantTableV2/SET_APPLICANTS';
export const SET_APPLICANTS_SUCCESS =
  'app/ApplicantTableV2/SET_APPLICANTS_SUCCESS';
export const SET_APPLICANTS_ERROR = 'app/ApplicantTableV2/SET_APPLICANTS_ERROR';
export const DELETE_APPLICANT_DETAIL =
  'app/ApplicantTableV2/DELETE_APPLICANT_DETAIL';
export const DELETE_NOTE = 'app/ApplicantTableV2/DELETE_NOTE';
export const UPDATE_APPLICANT_DETAIL =
  'app/ApplicantTableV2/UPDATE_APPLICANT_DETAIL';
export const UPDATE_APPLICANT_DATA_FIELD_COLUMN =
  'app/ApplicantTableV2/UPDATE_APPLICANT_DATA_FIELD_COLUMN';
export const CLEAR_APPLICANT_DATA_FIELD_COLUMN =
  'app/ApplicantTableV2/CLEAR_APPLICANT_DATA_FIELD_COLUMN';
export const SEND_WHATSAPP_CONVERSATION_REQUEST =
  'app/ApplicantTableV2/SEND_WHATSAPP_CONVERSATION_REQUEST';
export const SET_APPLICANT_EXTRA_DETAIL =
  'app/ApplicantTableV2/SET_APPLICANT_EXTRA_DETAIL';
export const SET_APPLICANT_NOTES = 'app/ApplicantTableV2/SET_APPLICANT_NOTES';
export const SET_APPLICANT_MESSAGES =
  'app/ApplicantTableV2/SET_APPLICANT_MESSAGES';
export const SET_APPLICANT_MESSAGING_THREAD =
  'app/ApplicantTableV2/SET_APPLICANT_MESSAGING_THREAD';
export const RESET_APPLICANT_UNREAD_MESSAGES =
  'app/ApplicantTableV2/RESET_APPLICANT_UNREAD_MESSAGES';
export const SET_SELECTED_APPLICANT_IDS =
  'app/ApplicantTableV2/SET_SELECTED_APPLICANT_IDS';
export const RESET_SELECTED_APPLICANT_IDS =
  'app/ApplicantTableV2/RESET_SELECTED_APPLICANT_IDS';
export const REMOVE_SELECTED_APPLICANT_ID =
  'app/ApplicantTableV2/REMOVE_SELECTED_APPLICANT_ID';
export const SET_SELECT_BY_COUNT = 'app/ApplicantTableV2/SET_SELECT_BY_COUNT';
export const UPDATE_NOTE = 'app/ApplicantTableV2/UPDATE_NOTE';
export const UPDATE_TEMP_MESSAGE = 'app/ApplicantTableV2/UPDATE_TEMP_MESSAGE';
export const REMOVE_APPLICANT_FROM_STAGE =
  'app/ApplicantTableV2/REMOVE_APPLICANT_FROM_STAGE';
export const REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE =
  'app/ApplicantTableV2/REMOVE_APPLICANT_FROM_STAGE_ON_CLOSE';
export const REMOVE_APPLICANT_MESSAGE =
  'app/ApplicantTableV2/REMOVE_APPLICANT_MESSAGE';
export const REMOVE_APPLICANT_TEMP_MESSAGE =
  'app/ApplicantTableV2/REMOVE_APPLICANT_TEMP_MESSAGE';
export const RESET_APPLICANT_DATA = 'app/ApplicantTableV2/RESET_APPLICANT_DATA';
export const SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS =
  'app/ApplicantTableV2/SET_SELECTED_FILTER_STAGE_IDS_WITH_FUNNELS';
export const UPDATE_TABLE_APPLICANT_STATUSES =
  'app/ApplicantTableV2/UPDATE_TABLE_APPLICANT_STATUSES';
export const UPDATE_PROFILE_APPLICANT_STATUSES =
  'app/ApplicantTableV2/UPDATE_PROFILE_APPLICANT_STATUSES';
export const SET_SELECTED_APPLICANT_DETAIL =
  'app/ApplicantTableV2/SET_SELECTED_APPLICANT_DETAIL';
export const UPDATE_WHATSAPP_MESSAGE =
  'app/ApplicantTableV2/UPDATE_WHATSAPP_MESSAGE';
export const SET_APPLICANT_DETAIL = 'app/ApplicantTableV2/SET_APPLICANT_DETAIL';
export const CLEAR_APPLICANT_CACHE =
  'app/ApplicantTableV2/CLEAR_APPLICANT_CACHE';
export const SET_IS_OVERLAY_VISIBILE_TRUE =
  'app/ApplicantTableV2/SET_IS_OVERLAY_VISIBILE_TRUE';
export const SET_IS_OVERLAY_VISIBILE_FALSE =
  'app/ApplicantTableV2/SET_IS_OVERLAY_VISIBILE_FALSE';
export const UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID =
  'app/ApplicantTableV2/UPDATE_MESSAGE_DATA_KEY_BY_MESSAGE_ID';
export const UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID =
  'app/ApplicantTableV2/UPDATE_MESSAGE_MEDIA_DELETED_BY_MESSAGE_ID';
export const SET_BOT_SUGGESTION_SENT =
  'app/ApplicantTableV2/SET_BOT_SUGGESTION_SENT';

export const RESIZE_TABLE_COLUMN = 'app/ApplicantTableV2/RESIZE_TABLE_COLUMN';
export const ASC = 'asc';
export const DESC = 'desc';
export const SELECT_ALL = 'all'; // HI-862
export const DEFAULT_ROWS_PER_PAGE = 20;
// must be ordered lowest to highest
export const ROWS_PER_PAGE = [10, 20, 50, 100];

export const TestId = {
  PAGINATION: 'ApplicantTableV2-Pagination',
  BULK_ACTIONS_FOOTER: 'ApplicantTableV2-Bulk-Actions-Footer',
  CUSTOMIZE_COLUMNS: 'ApplicantTableV2-customizable-columns-drawer',
  TABLE: 'ApplicantTableV2',
  TABLE_LOADING: 'LoadingApplicantTableV2',
  SELECT_ALL: 'ApplicantTableV2-Bulk-Actions-Footer-SelectAll',
  SELECT_BY_COUNT: 'ApplicantTableV2-Bulk-Actions-Footer-SelectByCount',
};

export const COLUMN_TYPES: Record<string, TableColumnWidthsKey> = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  USER: 'assigned_user',
  STATUS: 'statuses',
  MESSAGE_COUNT: 'message_count',
  CUSTOM_LABEL: 'custom_label',
  APPLIED: 'applied_at',
  LANDED: 'landed_at',
  STAGE: 'stage',
  ACCOUNT: 'account',
  OPENING: 'opening',
  IDLE: 'idle_since',
  LOCATION: '_location',
  PROGRESS: 'progress',
  BRAND: 'brand',
  UTM_SOURCE: 'utm_source',
  NOTE: 'note',
  DATE_OF_BIRTH: 'date_of_birth',
  PHONE_PLATFORM: 'phone_platform',
  APPLICATION_LOCALE: 'application_locale',
  IDLE_MOVE_OUT_IN: 'idle_move_out_in',
  ARCHIVED_REASON: 'archived_reason',
  FOLLOW_UP: 'follow_up_time',
  TYPE: '_type',
};

export const STAGES: Record<string, string> = {
  SCHEDULER_STAGE: 'SchedulerStage',
  HIRED_STAGE: 'HiredStage',
};

export const USER_COLUMN_WIDTH = 74;
export const DATE_COLUMN_WIDTH = 135;
export const PHONE_PLATFORM_COLUMN_WIDTH = 120;
export const APPLICATION_LOCALE_WIDTH = 200;
export const FOLLOW_UP_TIME_WIDTH = 150;
export const TYPE_COLUMN_WIDTH = 58;
export const IDLE_MOVE_OUT_IN_COLUMN_WIDTH = 140;

export const COL_WIDTH_ATTR_BY_TYPE: Record<keyof typeof COLUMN_TYPES, string> =
  {
    [COLUMN_TYPES.NAME]: 'nameColumnWidth',
    [COLUMN_TYPES.EMAIL]: 'emailColumnWidth',
    [COLUMN_TYPES.PHONE]: 'phoneColumnWidth',
    [COLUMN_TYPES.STATUS]: 'statusColumnWidth',
    [COLUMN_TYPES.MESSAGE_COUNT]: 'unreadMessagesCountColumnWidth',
    [COLUMN_TYPES.PROGRESS]: 'progressColumnWidth',
    [COLUMN_TYPES.CUSTOM_LABEL]: 'customLabelColumnWidth',
    [COLUMN_TYPES.APPLIED]: 'appliedColumnWidth',
    [COLUMN_TYPES.LANDED]: 'landedColumnWidth',
    [COLUMN_TYPES.STAGE]: 'stageColumnWidth',
    [COLUMN_TYPES.OPENING]: 'openingColumnWidth',
    [COLUMN_TYPES.IDLE]: 'idleColumnWidth',
    [COLUMN_TYPES.PHONE_PLATFORM]: 'phonePlatform',
    [COLUMN_TYPES.APPLICATION_LOCALE]: 'applicationLocale',
    [COLUMN_TYPES.IDLE_MOVE_OUT_IN]: 'idleMoveOutInColumnWidth',
    [COLUMN_TYPES.FOLLOW_UP]: 'followUpTime',
  };

export const COLUMN_WIDTH_STYLES = {
  NAME: {
    minWidth: theme.customWidths.nameColumnWidth,
    maxWidth: theme.customWidths.nameColumnWidth,
  },
  EMAIL: {
    minWidth: theme.customWidths.emailColumnWidth,
    maxWidth: theme.customWidths.emailColumnWidth,
  },
  PHONE: {
    minWidth: theme.customWidths.phoneColumnWidth,
    maxWidth: theme.customWidths.phoneColumnWidth,
  },
  USER: {
    minWidth: USER_COLUMN_WIDTH,
    maxWidth: USER_COLUMN_WIDTH,
  },
  STATUS: {
    minWidth: theme.customWidths.statusColumnWidth,
    maxWidth: theme.customWidths.statusColumnWidth,
  },
  MESSAGE_COUNT: {
    minWidth: theme.customWidths.unreadMessagesCountColumnWidth,
    maxWidth: theme.customWidths.unreadMessagesCountColumnWidth,
  },
  PROGRESS: {
    maxWidth: theme.customWidths.progressColumnWidth,
    minWidth: theme.customWidths.progressColumnWidth,
  },
  CUSTOM_LABEL: {
    minWidth: theme.customWidths.customLabelColumnWidth,
    maxWidth: theme.customWidths.customLabelColumnWidth,
  },
  APPLIED: {
    minWidth: theme.customWidths.appliedColumnWidth,
    maxWidth: theme.customWidths.appliedColumnWidth,
  },
  LANDED: {
    minWidth: theme.customWidths.landedColumnWidth,
    maxWidth: theme.customWidths.landedColumnWidth,
  },
  STAGE: {
    minWidth: theme.customWidths.stageColumnWidth,
    maxWidth: theme.customWidths.stageColumnWidth,
  },
  OPENING: {
    minWidth: theme.customWidths.openingColumnWidth,
    maxWidth: theme.customWidths.openingColumnWidth,
  },
  ACCOUNT: {
    minWidth: theme.customWidths.openingColumnWidth,
    maxWidth: theme.customWidths.openingColumnWidth,
  },
  IDLE: {
    minWidth: theme.customWidths.idleColumnWidth,
    maxWidth: theme.customWidths.idleColumnWidth,
  },
  UTM_SOURCE: {
    minWidth: theme.customWidths.utmSourceColumnWidth,
    maxWidth: theme.customWidths.utmSourceColumnWidth,
  },
  NOTE: {
    minWidth: theme.customWidths.noteColumnWidth,
    maxWidth: theme.customWidths.noteColumnWidth,
  },
  DATE_OF_BIRTH: {
    minWidth: DATE_COLUMN_WIDTH,
    maxWidth: DATE_COLUMN_WIDTH,
  },
  PHONE_PLATFORM: {
    minWidth: PHONE_PLATFORM_COLUMN_WIDTH,
    maxWidth: PHONE_PLATFORM_COLUMN_WIDTH,
  },
  TYPE: {
    minWidth: TYPE_COLUMN_WIDTH,
    maxWidth: TYPE_COLUMN_WIDTH,
  },
  APPLICATION_LOCALE: {
    minWidth: APPLICATION_LOCALE_WIDTH,
    maxWidth: APPLICATION_LOCALE_WIDTH,
  },
  IDLE_MOVE_OUT_IN: {
    minWidth: IDLE_MOVE_OUT_IN_COLUMN_WIDTH,
    maxWidth: IDLE_MOVE_OUT_IN_COLUMN_WIDTH,
  },
  ARCHIVED_REASON: {
    minWidth: theme.customWidths.archivedReasonColumnWidth,
    maxWidth: theme.customWidths.archivedReasonColumnWidth,
  },
  FOLLOW_UP: {
    minWidth: FOLLOW_UP_TIME_WIDTH,
    maxWidth: FOLLOW_UP_TIME_WIDTH,
  },
};

// APPLICANT_TABLE_COLUMNS should only be an array of the
// actual columns being rendered in ApplicantTableV2
export const APPLICANT_TABLE_COLUMNS: TableColumnWidthsKey[] = [
  COLUMN_TYPES.USER,
  COLUMN_TYPES.MESSAGE_COUNT,
  COLUMN_TYPES.NAME,
  COLUMN_TYPES.EMAIL,
  COLUMN_TYPES.PHONE,
  COLUMN_TYPES.STATUS,
  COLUMN_TYPES.CUSTOM_LABEL,
  COLUMN_TYPES.APPLIED,
  COLUMN_TYPES.LANDED,
  COLUMN_TYPES.IDLE,
];

export const APPLICATION_LOCALE_COLUMN: TableColumnWidthsKey[] = [
  COLUMN_TYPES.APPLICATION_LOCALE,
];

// ADDITIONAL_APPLICANT_DATA is the additional applicant
// data that the ApplicantTableV2 relies on
export const ADDITIONAL_APPLICANT_DATA: TableColumnWidthsKey[] = [
  COLUMN_TYPES.LOCATION,
  COLUMN_TYPES.OPENING,
  COLUMN_TYPES.STAGE,
];

// MAV_COLUMNS should only be an array of the actual
// columns being rendered in the MAV table
export const MAV_COLUMNS: TableColumnWidthsKey[] = [
  COLUMN_TYPES.USER,
  COLUMN_TYPES.MESSAGE_COUNT,
  COLUMN_TYPES.NAME,
  COLUMN_TYPES.EMAIL,
  COLUMN_TYPES.PHONE,
  COLUMN_TYPES.PROGRESS,
  COLUMN_TYPES.STAGE,
  COLUMN_TYPES.OPENING,
  COLUMN_TYPES.ACCOUNT,
  COLUMN_TYPES.STATUS,
  COLUMN_TYPES.CUSTOM_LABEL,
  COLUMN_TYPES.LANDED,
  COLUMN_TYPES.IDLE,
];

// ADDITIONAL_MAV_DATA is the additional applicant
// data that the MAV table relies on
export const ADDITIONAL_MAV_DATA: TableColumnWidthsKey[] = [
  COLUMN_TYPES.LOCATION,
  COLUMN_TYPES.BRAND,
];

export const MAV_COLUMNS_DATA: TableColumnWidthsKey[] = [
  ...MAV_COLUMNS,
  ...ADDITIONAL_MAV_DATA,
];

// [AI-40] If the account is amazon-delivery-service-partner we want to add additional columns
export const AMAZON_DSP_TABLE_COLUMNS: TableColumnWidthsKey[] = [
  COLUMN_TYPES.UTM_SOURCE,
  COLUMN_TYPES.NOTE,
  COLUMN_TYPES.IDLE_MOVE_OUT_IN,
];

export const AMAZON_EU_TABLE_DEFAULT_COLUMNS: TableColumnWidthsKey[] = [
  COLUMN_TYPES.MESSAGE_COUNT,
  COLUMN_TYPES.CUSTOM_LABEL,
  COLUMN_TYPES.IDLE_MOVE_OUT_IN,
  COLUMN_TYPES.NAME,
  COLUMN_TYPES.EMAIL,
  COLUMN_TYPES.PHONE,
  COLUMN_TYPES.STATUS,
  COLUMN_TYPES.APPLIED,
  COLUMN_TYPES.UTM_SOURCE,
];

export const STATUS = 'Status';
